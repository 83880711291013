import { AppRoutes } from 'entity/routerConfig/ui/RouterConfig';
import React from 'react';
import { Link } from 'react-router-dom';
import { AppLinks } from 'shared/ui/applinks/AppLinks';
import { Texts } from 'shared/ui/texts/Texts';

const ServicesPageDev = () => {
    return (
        <div className='services_page_wrapper'>
        <div className='container'>
        <div className='services_page_header'>
                        <h1><Texts>Поддержка и развитие</Texts></h1>
                        <nav className="inner_nav">
                            <ul className="inner_nav_list" role='list'>
                                <li className='inner_nav_item'>
                                   <Link to={AppRoutes.SERVICES}> <AppLinks aria-current="page">Разработка</AppLinks></Link>
                                </li>
                                <li className='inner_nav_item '>
                                   <Link to={AppRoutes.SERVICES_DEV}><AppLinks className='active' aria-current="page">Поддержка и развитие</AppLinks></Link> 
                                </li>
                                <li className='inner_nav_item'>
                                    <Link to={AppRoutes.SERVICES_FRONT}><AppLinks  aria-current="page">ИТ-аутсорсинг</AppLinks></Link> 
                                </li> 
                            </ul>
                        </nav>
                    </div>
            <div className='services_page_grid'>
                <div className='services_page_content'>
                    <h2 className='title_content'><Texts >Этапы развития</Texts></h2>
                    <ol role='list' className='ol'>
                            <li className='li'>
                                <Texts>
                                Разработка клиент-серверных приложений на основе микросервисной архитектуры Service mesh.
                                </Texts>
                            </li>
                            <li className='li'>
                                <Texts>
                                Разработка и внедрение систем аналитики данных, управленческой отчетности.
                                </Texts>
                            </li>
                            <li className='li'>
                                <Texts>
                                Настройка всех видов интеграций ИТ-продуктов и систем в единую инфраструктуру компании.
                                </Texts>
                            </li>
                            <li className='li'>
                                <Texts>
                                Настройка интеграций с внешними сервисами, с ГИС системами (ЕГАИС, Честный знак, Меркурий, ФГИС Зерно, ЭТрН).
                                </Texts>
                            </li>
                            <li className='li'>
                                <Texts>
                                Разработка под СУБД MS SQL, PostgreSQL.
                                </Texts>
                            </li>
                            <li className='li'>
                                <Texts>
                                Оценка информационных технологий компании.
                                </Texts>
                            </li>
                    </ol>
                    <h2 className='title_content'><Texts >Стек технологий</Texts></h2>
                    <Texts>Используем современные фреймворки: Flutter, Spring boot, Reactjs. База данных:  MS SQL, PostgreSQL. Готовы рассмотреть проекты на другом стеке, при условии параллельной разработки новой версии на наших технологиях.</Texts>
                    <Link to={AppRoutes.ABOUT_TECH}><Texts><AppLinks>Показать полный стек</AppLinks></Texts></Link>
                    <h2 className='title_content'><Texts >Оценка текущего состояния приложения</Texts></h2>
                    <Texts>К сожалению, мы не сможем начать работу, пока не проведём предварительную оценку проекта, которая позволит избавить обе стороны от недопониманий в процессе разработки проекта.</Texts>
                    {/* <h2 className='title_content'><Texts >Ежемесячная оплата по Time&Material</Texts></h2>
                    <Texts>Оплата ежемесячно исходя из фактических трудозатрат (Time&Material). Выдаём предварительную оценку стоимости по задачам в течение одного рабочего дня.</Texts> */}
                
                    {/* <Texts className='footer_content'>→ Отправить завяку на <Link to={AppRoutes.FEEDBACK}><AppLinks>разработку сайта</AppLinks></Link></Texts> */}
                </div>
                <div>
                        <div className='aside_nav'>
                            <div className='aside_title'><Texts width={600}>Компания</Texts> </div>
                            <ul role='aside_list'>
                                <li className='aside_nav_item'>
                                <Link to={AppRoutes.ABOUT}><Texts><AppLinks>О компании</AppLinks></Texts> </Link> 
                                </li>
                                <li className='aside_nav_item'>
                                    <Link to={AppRoutes.ABOUT_TECH}><Texts><AppLinks>Стек технологий</AppLinks></Texts></Link>
                                </li>
                                <li className='aside_nav_item'>
                                   <Link to={AppRoutes.ABOUT_VACANCY}><Texts><AppLinks>Вакансии</AppLinks></Texts> </Link>   
                                </li>
                            </ul>
                        </div>
                    </div>
            </div>
        </div>
    </div>
    );
};

export default ServicesPageDev;