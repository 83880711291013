// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.font_s {
    font-size: 14px;
    color: black;
 }
 
 .font_m {
    font-size: 18px;
    color: black;
    margin: 0;
 }
 
 .font_l {
    color: black;
    font-size: 24px;
    margin: 0;
 }
 
 .font_xl {
    color: black;
    font-size: 36px;
    margin: 0;
 }
 
 .font_xxl {
    color: black;
    font-size: 40px;
    line-height: 60px;
    margin: 0;
 }
 
 .center {
    text-align: center;
 }
 
 .left {
    text-align: left;
 }
 
 .right {
    text-align: right;
 }
 
`, "",{"version":3,"sources":["webpack://./src/shared/ui/texts/Texts.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;CACf;;CAEA;IACG,eAAe;IACf,YAAY;IACZ,SAAS;CACZ;;CAEA;IACG,YAAY;IACZ,eAAe;IACf,SAAS;CACZ;;CAEA;IACG,YAAY;IACZ,eAAe;IACf,SAAS;CACZ;;CAEA;IACG,YAAY;IACZ,eAAe;IACf,iBAAiB;IACjB,SAAS;CACZ;;CAEA;IACG,kBAAkB;CACrB;;CAEA;IACG,gBAAgB;CACnB;;CAEA;IACG,iBAAiB;CACpB","sourcesContent":[".font_s {\n    font-size: 14px;\n    color: black;\n }\n \n .font_m {\n    font-size: 18px;\n    color: black;\n    margin: 0;\n }\n \n .font_l {\n    color: black;\n    font-size: 24px;\n    margin: 0;\n }\n \n .font_xl {\n    color: black;\n    font-size: 36px;\n    margin: 0;\n }\n \n .font_xxl {\n    color: black;\n    font-size: 40px;\n    line-height: 60px;\n    margin: 0;\n }\n \n .center {\n    text-align: center;\n }\n \n .left {\n    text-align: left;\n }\n \n .right {\n    text-align: right;\n }\n \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
