
import './App.css';
import  { AppRouter } from './entity/routerConfig/index'
import { Navbar } from './widgets/navbar';
import { Footerbar } from './widgets/footerbar';
function App() {
  return (
    <div className="App">
      <Navbar />
      <AppRouter />
      {/* <Footerbar /> */}
    </div>
  );
}

export default App;
