
import { AboutPage } from 'pages/aboutPage';
import { AboutPageTech } from 'pages/aboutPageTech';
import { AboutPageVacancy } from 'pages/aboutPageVacancy';
import { ContactsPage } from 'pages/contactsPage';
import { FeedbackPage } from 'pages/feedbackPage';
import { Mainpage } from 'pages/mainpage';
import { NotFoundPage } from 'pages/notFoundPage/index';
import { ServicesPage } from 'pages/servicesPage';
import { ServicesPageDev } from 'pages/servicesPageDev';
import { ServicesPageFront } from 'pages/servicesPageFront';
import React from 'react';
import { RouteProps } from 'react-router-dom';

export enum AppRoutes {
   MAIN = '/',
   FEEDBACK = 'feedback',
   SERVICES = 'services',
   SERVICES_FEEDBACK = '/services/feedback',
   SERVICES_DEV = '/services/dev',
   SERVICES_DEV_ABOUT = '/services/dev/about',
   SERVICES_DEV_TECH = '/services/dev/tech',
   SERVICES_DEV_VACANCY = '/services/dev/vacancy',
   SERVICES_FRONT = '/services/front',
   SERVICES_FRONT_ABOUT = '/services/front/about',
   SERVICES_FRONT_TECH = '/services/front/tech',
   SERVICES_FRONT_VACANCY = '/services/front/vacancy',
   SERVICES_ABOUT = '/services/about',
   ABOUT = 'about',
   ABOUT_TECH = '/about/tech',
   ABOUT_VACANCY = '/about/vacancy',
   CONTACTS = '/contacts',
   NOTFOUND = 'notfound',
}
 
export const RoutePath: Record<AppRoutes, string> = {
    [AppRoutes.MAIN]: '/',
    [AppRoutes.FEEDBACK]: '/feedback',
    [AppRoutes.SERVICES_FEEDBACK]: '/services/feedback',
    [AppRoutes.SERVICES_DEV]: '/services/dev',
    [AppRoutes.SERVICES_DEV_ABOUT]: '/services/dev/about',
    [AppRoutes.SERVICES_DEV_TECH]: '/services/dev/tech',
    [AppRoutes.SERVICES_DEV_VACANCY]: '/services/dev/vacancy',
    [AppRoutes.SERVICES_FRONT]: '/services/front',
    [AppRoutes.SERVICES_FRONT_ABOUT]: '/services/front/about',
    [AppRoutes.SERVICES_FRONT_TECH]: '/services/front/tech',
    [AppRoutes.SERVICES_FRONT_VACANCY]: '/services/front/vacancy',
    [AppRoutes.SERVICES_ABOUT]: '/services/about/*',
    [AppRoutes.SERVICES]: '/services/*',
    [AppRoutes.ABOUT]: '/about/*',
    [AppRoutes.ABOUT_TECH]: '/about/tech',
    [AppRoutes.ABOUT_VACANCY]: '/about/vacancy',
    [AppRoutes.CONTACTS]: '/contacts/*',
    [AppRoutes.NOTFOUND]: '*',
};

export const RouteConfig: Record<AppRoutes, RouteProps> = {
    [AppRoutes.MAIN]: {
        path: RoutePath[AppRoutes.MAIN],
        element: <Mainpage />,
    },
    [AppRoutes.FEEDBACK]: {
        path: RoutePath[AppRoutes.FEEDBACK],
        element: <FeedbackPage />,
    },
    [AppRoutes.SERVICES]: {
        path: RoutePath[AppRoutes.SERVICES],
        element: <ServicesPage />,
    },
    [AppRoutes.SERVICES_FEEDBACK]: {
        path: RoutePath[AppRoutes.SERVICES_FEEDBACK],
        element: <FeedbackPage />,
    },
    [AppRoutes.SERVICES_DEV]: {
        path: RoutePath[AppRoutes.SERVICES_DEV],
        element: <ServicesPageDev />,
    },
    [AppRoutes.SERVICES_DEV_ABOUT]: {
        path: RoutePath[AppRoutes.SERVICES_DEV_ABOUT],
        element: <AboutPage />,
    },
    [AppRoutes.SERVICES_DEV_TECH]: {
        path: RoutePath[AppRoutes.SERVICES_DEV_TECH],
        element: <AboutPageTech />,
    },
    [AppRoutes.SERVICES_DEV_VACANCY]: {
        path: RoutePath[AppRoutes.SERVICES_DEV_VACANCY],
        element: <AboutPageVacancy />,
    },
    [AppRoutes.SERVICES_FRONT]: {
        path: RoutePath[AppRoutes.SERVICES_FRONT],
        element: <ServicesPageFront />,
    },
    [AppRoutes.SERVICES_FRONT_ABOUT]: {
        path: RoutePath[AppRoutes.SERVICES_FRONT_ABOUT],
        element: <AboutPage />,
    },
    [AppRoutes.SERVICES_FRONT_TECH]: {
        path: RoutePath[AppRoutes.SERVICES_FRONT_TECH],
        element: <AboutPageTech />,
    },
    [AppRoutes.SERVICES_FRONT_VACANCY]: {
        path: RoutePath[AppRoutes.SERVICES_FRONT_VACANCY],
        element: <AboutPageVacancy />,
    },
    [AppRoutes.SERVICES_ABOUT]: {
        path: RoutePath[AppRoutes.SERVICES_ABOUT],
        element: <AboutPage />,
    },
    [AppRoutes.ABOUT]: {
        path: RoutePath[AppRoutes.ABOUT],
        element: <AboutPage />,
    },
    [AppRoutes.ABOUT_TECH]: {
        path: RoutePath[AppRoutes.ABOUT_TECH],
        element: <AboutPageTech />,
    },
    [AppRoutes.ABOUT_VACANCY]: {
        path: RoutePath[AppRoutes.ABOUT_VACANCY],
        element: <AboutPageVacancy />,
    },
    [AppRoutes.CONTACTS]: {
        path: RoutePath[AppRoutes.CONTACTS],
        element: <ContactsPage />,
    },
    [AppRoutes.NOTFOUND]: {
        path: RoutePath[AppRoutes.NOTFOUND],
        element: <NotFoundPage />,
    },
};