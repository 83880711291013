import { AppRoutes } from 'entity/routerConfig/ui/RouterConfig';
import React from 'react';
import { Link } from 'react-router-dom';
import { AppLinks } from 'shared/ui/applinks/AppLinks';
import { Texts } from 'shared/ui/texts/Texts';

const AboutPageVacancy = () => {
    return (
        <div>
            <div className='services_page_wrapper'>
                <div className='container'>
                    <div className='services_page_header'>
                            <h1><Texts>Вакансии</Texts></h1>
                            <nav className="inner_nav">
                                <ul className="inner_nav_list" role='list'>
                                    <li className='inner_nav_item'>
                                    <Link to={AppRoutes.ABOUT}> <AppLinks  aria-current="page">О компании</AppLinks></Link>
                                    </li>
                                    <li className='inner_nav_item'>
                                    <Link to={AppRoutes.ABOUT_TECH}><AppLinks  aria-current="page">Стек технологий</AppLinks></Link> 
                                    </li>
                                    <li className='inner_nav_item'>
                                    <Link to={AppRoutes.ABOUT_VACANCY}><AppLinks className='active' aria-current="page">Вакансии</AppLinks></Link> 
                                    </li>
                                </ul>
                            </nav>
                        </div>
            <div className='services_page_grid'>
                <div className='services_page_content'>
                <h1 ><Texts >Работа в компании</Texts></h1>
                <Texts className='p'> Разрабатываем и развиваем различные приложения на все виды платформ, используя современные JAVA, JavaScript, Dart языки программирования
                 (<Link to={AppRoutes.ABOUT_TECH}><AppLinks>стек используемых технологий</AppLinks></Link> ).</Texts>
                 <h2 className='title_content'><Texts >Для наших сотрудников мы предлагаем:</Texts></h2>
                    <ul role='list' className='ul'>
                        <li className='li'>
                            <Texts>
                            Интересные проекты и широкий спектр применяемых ИТ-технологий.
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            Большие возможности для развития и профессионального роста
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            Стабильная "белая" заработная плата.
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            Оплачиваемый отпуск и больничный лист
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            Обучение за счет компании
                            </Texts>
                        </li>
                    </ul>
                    {/* <h2 className='title_content'><Texts >Как откликнуться?</Texts></h2>
                    <Texts>Прислать письмо на <AppLinks>job@15web.ru</AppLinks> . Желательное содержание:</Texts>
                    <ol role='list' className='ol'>
                        <li className='li'>
                            <Texts>
                            Короткий рассказ о себе и реализованных проектах с описанием вашей роли.
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            Ожидаемая стоимость часа работы.
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            Часовой пояс и временное окно для работы.
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            Уровень желаемой загрузки (часов в день и месяц).
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            Для разработчиков: файлы с примерами кода, либо ссылка на профиль в GitHub.
                            </Texts>
                        </li>
                    </ol>
                    <Texts className='p'>Стек не полный, если вам необходима другая технология или фреймворк — обращайтесь.</Texts>
                    <blockquote className='blockquote'>
                    С примерами нашего кода и стандартами его написания можно ознакомиться на GitHub: <br />
                    <ul role='list' className='ul'>
                        <li className='li'>
                            <Texts>
                           <AppLinks>Symfony starter kit.</AppLinks>
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            <AppLinks>Laravel starter kit.</AppLinks>
                            </Texts>
                        </li>
                    </ul>
                    </blockquote>
              
                <h3><Texts>Бэкенд</Texts></h3>
                <ul role='list' className='ul'>
                        <li className='li'>
                            <Texts>
                            Руководствуемся различными принципами разработки: KISS, DRY, SOLID, GRASP, YAGNI и другие.
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            Symfony для разработки, поддержки и дальнейшего масштабирования сложных проектов.
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            Laravel для разработки и быстрого старта небольших и средних проектов.
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            MySQL, PostgreSQL.
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            Системы контроля версий: GitLab, GitHub, Bitbucket.
                            </Texts>
                        </li>
                    </ul>
                    <h3><Texts>Фронтенд</Texts></h3>
                    <Texts className='p'>Разрабатываем как клиентские, так и серверные приложения, используя библиотеки React (Next.js), Vue (Nuxt.js):</Texts>
                     <ul role='list' className='ul'>
                        <li className='li'>
                            <Texts>
                            Модульная архитектура.
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            SPA / SPA + SSR.
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            Nuxt.js — развиваем и разрабатываем проекты на второй и третьей версиях.
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            Строгое описание типов с помощью Typescript.
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            Взаимодействуем с сервером через классический REST API или GraphQL.
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            Автоматическая проверка стиля кода инструментами Stylelint и ESLint.
                            </Texts>
                        </li>
                    </ul>
                    <h3><Texts>Аналитика и проектирование</Texts></h3>
                     <ul role='list' className='ul'>
                        <li className='li'>
                            <Texts>
                            Интервьюирование клиента, погружение в предметную область и бизнес-процессы.
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            Формирование модульной системы и схем бизнес-процессов посредством Event Storming. Метод позволяет синхронизировать бизнес и разработку. В качестве инструмента реализации методологии применяем Miro.
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            Проработка интерфейсных решений и их обоснование.
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            Разработка прототипов с адаптивом в виде схем-макетов, либо интерактивных страниц.
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            Анализ конкурентного поля и другая аналитика
                            </Texts>
                        </li>
                    </ul> */}
                </div>
                <div>
                        <div className='aside_nav'>
                            <div className='aside_title'><Texts width={600}>Компания</Texts> </div>
                            <ul role='aside_list'>
                                <li className='aside_nav_item'>
                                <Link to={AppRoutes.SERVICES_DEV}><Texts><AppLinks>Разработка приложений</AppLinks></Texts> </Link> 
                                </li>
                                <li className='aside_nav_item'>
                                  <Link to={AppRoutes.SERVICES_FRONT}> <Texts><AppLinks>Приемущества аутсорсинга</AppLinks></Texts></Link> 
                                </li>
                            </ul>
                        </div>
                    </div>
            </div>
        </div>
    </div>
    </div>
   
    );
};

export default AboutPageVacancy;