import { AppRoutes } from 'entity/routerConfig/ui/RouterConfig';
import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
    return (
        <div>
            <h1> Страница не найдена или в разработке. <br /> <Link to={AppRoutes.MAIN}> Вернуться на главную </Link> </h1>
        </div>
    );
};

export default NotFoundPage;