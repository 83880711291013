import { AppRoutes } from 'entity/routerConfig/ui/RouterConfig';
import React from 'react';
import { Link } from 'react-router-dom';
import { AppLinks } from 'shared/ui/applinks/AppLinks';
import { Texts } from 'shared/ui/texts/Texts';

const AboutPage = () => {
    return (
        <div>
            <div>
            <div className='services_page_wrapper'>
            <div className='container'>
            <div className='services_page_header'>
                            <h1><Texts>О компании</Texts></h1>
                            <nav className="inner_nav">
                                <ul className="inner_nav_list" role='list'>
                                    <li className='inner_nav_item'>
                                       <Link to={AppRoutes.ABOUT}> <AppLinks  className='active' aria-current="page">О компании</AppLinks></Link>
                                    </li>
                                    <li className='inner_nav_item'>
                                       <Link to={AppRoutes.ABOUT_TECH}><AppLinks  aria-current="page">Стек технологий</AppLinks></Link> 
                                    </li>
                                    <li className='inner_nav_item'>
                                       <Link to={AppRoutes.ABOUT_VACANCY}><AppLinks aria-current="page">Вакансии</AppLinks></Link> 
                                    </li>
                                </ul>
                            </nav>
                        </div>
                <div className='services_page_grid'>
                    <div className='services_page_content'>
                    <h2 className='title_content'><Texts >Немного о нашей компании</Texts></h2>
                        <blockquote className='blockquote'>
                        ООО ИТ Мастер - системный интегратор и разработчик программного обеспечения. Разрабатываем, внедряем и сопровождаем сложные ИТ- продукты и системы под ключ, решаем отдельные задачи клиента.
                        </blockquote>
                        <Texts className='p'>Предлагаем полный спектр услуг от анализа и проектирования ваших бизнес-процессов до внедрения и поддержки готовых решений. Интегрируем проекты с внешними сервисами и ГИС системами. Проектируем архитектуру, применяем инструменты непрерывной интеграции, тестирования и развёртывания.</Texts>
                        <Texts className='p'>Проводим комплексную оценку информационных технологий вашей компании, оптимизируем ИТ-процессы для повышения эффективности бизнеса.</Texts>
                        <Texts className='p'>Мы – команда профессионалов с большим опытом разработки и внедрения программного обеспечения, интеграции и поддержки ИТ-систем. Подходим индивидуально к каждому клиенту, выстраиваем доверительные партнерские отношения. Постоянно развиваемся и осваиваем новые технологии, решаем сложные задачи и участвуем в крупных проектах.</Texts>
                        {/* <h2 className='title_content'><Texts >Почему мы?</Texts></h2>
                        <h3><Texts >Небольшая сработавшаяся команда опытных профессионалов</Texts></h3>
                        <Texts className='p'>Мы будем полезны, если вам регулярно нужно решать сложные задачи силами небольшой команды профи. Здесь мы похожи на спецназ или ниндзя! 
                        🐱👤 Запускаем сложные проекты, решаем нетривиальные задачи, даже с которыми сталкиваемся впервые, с гарантированным качеством.</Texts>
                        <Texts className='p'>В штате находится около 20 сотрудников. Такой формат позволяет минимизировать операционные расходы, 
                        а значит предлагать конкурентную стоимость работ.</Texts>
                        <Texts className='p'>Многие сотрудники работают в компании три, пять, десять лет, 
                        а некоторые ведущие менеджеры и тимлиды по 15. Достигаем этого, предоставляя разработчикам возможности для быстрого профессионального роста, участия в крупных проектах и решения сложных задач, а также менторство со стороны тимлидов. 
                        Выстраиваем доверительные партнёрские отношения. Люди достойно зарабатывают.</Texts>
                        <Texts className='p'> 90 % сотрудников имеют квалификацию middle и выше. Специалистов с небольшим опытом привлекаем только на внутренние задачи 
                        и для уменьшения затрат клиентов без ущерба для качества при непрерывном контроле квалифицированными разработчиками.</Texts>
                        <h3><Texts >Умная разработка</Texts></h3>
                        <Texts className='p'>Сначала думаем, обсуждаем, документируем и только потом делаем.</Texts>
                        <Texts className='p'>Не просто выполняем ТЗ заказчика «в лоб», а вникаем в проблемы, цели и задачи, предлагаем альтернативные решения и варианты.
                         Только этим сэкономили миллионы рублей клиентам.</Texts>
                         <Texts className='p'>Непрерывно развиваем <b> инженерную культуру</b>, которая улучшает качество разрабатываемых продуктов и позволяет браться за новые, сложные вызовы. Уделяем внимание процессам и коммуникации: 
                         аналитике проектов, постановке задач, автоматизации инфраструктуры, контролю качества кода и тестированию.</Texts>
                         <h3><Texts >Высокий уровень проектного управления</Texts></h3>
                         <Texts className='p'>Каждый проект для нас <b>штучный</b>, а не один из многих. Поэтому предоставляем индивидуальный подход, оперативность и гибкость. 
                         Клиент видит прогресс каждую неделю, он понимает что происходит, может управлять развитием проекта в реальном времени.</Texts>
                         <Texts className='p'>Средний срок работы с клиентами 7,5 лет. Налаживаем долгосрочность сотрудничества. Поэтому даже при первом контакте наша задача не продать, а <b>проконсультировать</b>  и показать свою экспертность, чтобы клиент вернулся к нам позднее, если мы не договоримся сейчас. 
                         Именно так, через несколько месяцев (были случаи и через год) после первого контакта, к нам возвращались впоследствии самые постоянные наши клиенты.</Texts>
                         <h3><Texts>Собственные проекты</Texts></h3>
                         <Texts className='p'>Имеем опыт запуска нескольких собственных интернет-проектов (больше этого делать не будем в рамках одной компании).</Texts>
                         <Texts className='p'>Делимся наработками в open source по адаптации сайтов для людей с ограниченными возможностями, SDK для разных сервисов, 
                         различными Starter Kit (с примерами нашего кода и стандартами его написания можно ознакомиться на GitHub: <AppLinks>Symfony starter kit</AppLinks> , 
                         <AppLinks>Laravel starter kit</AppLinks>).</Texts> */}
                         {/* <h2 className='title_content'><Texts >С нами работают лидеры в своих нишах</Texts></h2>
                        <ul role='list' className='ul'>
                            <li className='li'>
                                <Texts>
                                ООО УК "Молния Недвижимость".
                                </Texts>
                            </li>
                            <li className='li'>
                                <Texts>
                                ООО "МОЛЛ", торговая сеть "Молния - SPAR".
                                </Texts>
                            </li>
                            <li className='li'>
                                <Texts>
                                ООО «Лоймакс» - система лояльности
                                </Texts>
                            </li>
                    
                        </ul> */}
                    </div>
                    <div>
                        <div className='aside_nav'>
                            <div className='aside_title'><Texts width={600}>Компания</Texts> </div>
                            <ul role='aside_list'>
                                <li className='aside_nav_item'>
                                <Link to={AppRoutes.SERVICES_DEV}><Texts><AppLinks>Развитие приложений</AppLinks></Texts> </Link> 
                                </li>
                                <li className='aside_nav_item'>
                                  <Link to={AppRoutes.SERVICES_FRONT}> <Texts><AppLinks>Приемущества аутсорсинга</AppLinks></Texts></Link> 
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </div>
    );
};

export default AboutPage;