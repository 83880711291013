import { AppRoutes } from 'entity/routerConfig/ui/RouterConfig';
import React from 'react';
import { Link } from 'react-router-dom';
import { AppLinks } from 'shared/ui/applinks/AppLinks';
import { Texts } from 'shared/ui/texts/Texts';

const AboutPageTech = () => {
    return (
        <div>
        <div>
        <div className='services_page_wrapper'>
        <div className='container'>
        <div className='services_page_header'>
                        <h1><Texts>Стек используемых технологий</Texts></h1>
                        <nav className="inner_nav">
                            <ul className="inner_nav_list" role='list'>
                                <li className='inner_nav_item'>
                                   <Link to={AppRoutes.ABOUT}> <AppLinks   aria-current="page">О компании</AppLinks></Link>
                                </li>
                                <li className='inner_nav_item'>
                                   <Link to={AppRoutes.ABOUT_TECH}><AppLinks className='active' aria-current="page">Стек технологий</AppLinks></Link> 
                                </li>
                                <li className='inner_nav_item'>
                                   <Link to={AppRoutes.ABOUT_VACANCY}><AppLinks aria-current="page">Вакансии</AppLinks></Link> 
                                </li>
                            </ul>
                        </nav>
                    </div>
            <div className='services_page_grid'>
                <div className='services_page_content'>
                <h2 className='title_content'><Texts >Основной стек технологий</Texts></h2>
                    <ul role='list' className='ul'>
                        <li className='li'>
                            <Texts>
                            СУБД: MS SQL, PostgreSQL, HANA, ElasticSearch, SQLite.
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            Языки программирования: Java, C#, Dart, JavaScript, ABAP, Kotlin, 1С, Groovy, SQL, Python, VBA.
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            Фреймворки: Flutter, Reactjs, Spring boot, Helidon, Camel, Express, Koa, Akka, Dotnet core, Blazor.
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            Брокеры сообщений: Apache Kafka, RabbitMQ, MQTT.
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            Интеграционные шины (ESB): SAP PI/PO, MuleEsb, Node-Red.
                            </Texts>
                        </li>
                    </ul>
                    <Texts className='p'>Поддержка DevOps подходов CI (Continuous integration, Непрерывная интеграция) и CD (Continuous delivery, Непрерывная доставка)</Texts>
                    <h2 className='title_content'><Texts >Мы предлагаем полный комплекс услуг по разработке ПО:</Texts></h2>
                    <ul role='list' className='ul'>
                        <li className='li'>
                            <Texts>
                            Сбор и анализ потребности вашего бизнеса
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            Проектирование ИТ-архитектуры
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            Разработка 
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            Внедрение систем аналитики данных, управленческой отчетности  
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            Тестирование 
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            Системная интеграция
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            Внедрение приложений
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            Сопровождение и поддержка
                            </Texts>
                        </li>
                    </ul>
                {/* <h2 className='title_content'><Texts >Используемые технологии и подходы</Texts></h2>
                <h3><Texts>Бэкенд</Texts></h3>
                <ul role='list' className='ul'>
                        <li className='li'>
                            <Texts>
                            Руководствуемся различными принципами разработки: KISS, DRY, SOLID, GRASP, YAGNI и другие.
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            Symfony для разработки, поддержки и дальнейшего масштабирования сложных проектов.
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            Laravel для разработки и быстрого старта небольших и средних проектов.
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            MySQL, PostgreSQL.
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            Системы контроля версий: GitLab, GitHub, Bitbucket.
                            </Texts>
                        </li>
                    </ul>
                    <h3><Texts>Фронтенд</Texts></h3>
                    <Texts className='p'>Разрабатываем как клиентские, так и серверные приложения, используя библиотеки React (Next.js), Vue (Nuxt.js):</Texts>
                     <ul role='list' className='ul'>
                        <li className='li'>
                            <Texts>
                            Модульная архитектура.
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            SPA / SPA + SSR.
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            Nuxt.js — развиваем и разрабатываем проекты на второй и третьей версиях.
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            Строгое описание типов с помощью Typescript.
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            Взаимодействуем с сервером через классический REST API или GraphQL.
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            Автоматическая проверка стиля кода инструментами Stylelint и ESLint.
                            </Texts>
                        </li>
                    </ul>
                    <h3><Texts>Аналитика и проектирование</Texts></h3>
                     <ul role='list' className='ul'>
                        <li className='li'>
                            <Texts>
                            Интервьюирование клиента, погружение в предметную область и бизнес-процессы.
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            Формирование модульной системы и схем бизнес-процессов посредством Event Storming. Метод позволяет синхронизировать бизнес и разработку. В качестве инструмента реализации методологии применяем Miro.
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            Проработка интерфейсных решений и их обоснование.
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            Разработка прототипов с адаптивом в виде схем-макетов, либо интерактивных страниц.
                            </Texts>
                        </li>
                        <li className='li'>
                            <Texts>
                            Анализ конкурентного поля и другая аналитика
                            </Texts>
                        </li>
                    </ul> */}
                </div>
                <div>
                        <div className='aside_nav'>
                            <div className='aside_title'><Texts width={600}>Компания</Texts> </div>
                            <ul role='aside_list'>
                                <li className='aside_nav_item'>
                                <Link to={AppRoutes.SERVICES_DEV}><Texts><AppLinks>Развитие приложений</AppLinks></Texts> </Link> 
                                </li>
                                <li className='aside_nav_item'>
                                  <Link to={AppRoutes.SERVICES_FRONT}> <Texts><AppLinks>Приемущества аутсорсинга</AppLinks></Texts></Link> 
                                </li>
                            </ul>
                        </div>
                    </div>
            </div>
        </div>
    </div>
    </div>
    </div>
    );
};

export default AboutPageTech;