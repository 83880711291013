import React, { ReactNode } from 'react';
import './AppLinks.css';
import { Link } from 'react-router-dom';

interface AppLinksProps {
    className?: string,
    href?: any,
    children: ReactNode,
}

export const AppLinks = ({className = 'link_blue', href, children}: AppLinksProps ) => {
    return (
        <a className={className} href={href}>
            { children }
        </a>
    );
};