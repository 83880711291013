import React from 'react';
import Buttons, { SizeButtons } from 'shared/ui/buttons/Buttons';
import { TextAlign, TextSize, Texts } from 'shared/ui/texts/Texts';
import { Images } from 'shared/ui/images/Images';
import logo from 'shared/assets/main-logo.png';
import { Link } from 'react-router-dom';
import { AppRoutes } from 'entity/routerConfig/ui/RouterConfig';
import './Navbar.css';

const Navbar = () => {
    return (
        <div className='navbar'>
            <div className='navbar_logo'>
            {/* <Link to={AppRoutes.MAIN}><span title='Вернуться на главную' >  <Images className='logo'  img={logo}/></span></Link> */}
            <Link to={AppRoutes.MAIN}><span title='Вернуться на главную' >  <Texts size={TextSize.XXL} className='navbar_text_logo' width={700} ></Texts></span></Link>
            </div>
            <div className='nav'>
               <Link to={AppRoutes.SERVICES}  className='nav_item'> Услуги </Link> 
                <Link to={AppRoutes.ABOUT} className='nav_item'> Компания </Link> 
                <Link to={AppRoutes.CONTACTS} className='nav_item'> Контакты </Link> 
            </div>
           {/* <Link to={AppRoutes.FEEDBACK}><Buttons size={SizeButtons.L}><Texts color='white' size={TextSize.M} width={500} align='center'>Заказать проект</Texts></Buttons></Link> */}
        </div>
    );
};

export default Navbar;