import { Route, Routes } from 'react-router-dom';
import {RouteConfig} from './RouterConfig';
import React from 'react';

const AppRouter = () => (
    <div className="page_wrapper">
            <Routes>
                {Object.values(RouteConfig)?.map(({ element, path }) => (
                    <Route key={path} path={path} element={element} />
                ))}
            </Routes>
    </div>
);

export default AppRouter;