import React from 'react';
import './ServicesPage.css';
import { Texts } from 'shared/ui/texts/Texts';
import { AppLinks } from 'shared/ui/applinks/AppLinks';
import { Link } from 'react-router-dom';
import { AppRoutes } from 'entity/routerConfig/ui/RouterConfig';

const ServicesPage = () => {
    return (
        <div className='services_page_wrapper'>
            <div className='container'>
            <div className='services_page_header'>
                            <h1><Texts>Разработка</Texts></h1>
                            <nav className="inner_nav">
                                <ul className="inner_nav_list" role='list'>
                                    <li className='inner_nav_item active'>
                                       <Link to={AppRoutes.SERVICES}> <AppLinks className='active' aria-current="page">Разработка</AppLinks></Link>
                                    </li>
                                    <li className='inner_nav_item'>
                                       <Link to={AppRoutes.SERVICES_DEV}><AppLinks  aria-current="page">Поддержка и развитие</AppLinks></Link> 
                                    </li>
                                    <li className='inner_nav_item'>
                                       <Link to={AppRoutes.SERVICES_FRONT}><AppLinks  aria-current="page">ИТ-аутсорсинг</AppLinks></Link> 
                                    </li> 
                                </ul>
                            </nav>
                        </div>
                <div className='services_page_grid'>
                    <div className='services_page_content'>
                        <h2 className='title_content'><Texts >Проекты</Texts></h2>
                        <blockquote  className='blockquote'>
                        Специализируемся на разработке сложных проектов: сайтов, мобильных/серверных приложений, API, порталов; CRM, ERP и других корпоративных систем для любых платформ.
                        </blockquote>
                        <Texts>Мы оказываем широкий перечень ИТ-услуг:</Texts>
                        <ol role='list' className='ol'>
                            <li className='li'>
                                <Texts>
                                Поддержка и развитие систем SAP.
                                </Texts>
                            </li>
                            <li className='li'>
                                <Texts>
                                Разработка, внедрение и поддержка систем 1С.
                                </Texts>
                            </li>
                            <li className='li'>
                                <Texts>
                                Разработка, внедрение и поддержка WMS систем.
                                </Texts>
                            </li>
                            <li className='li'>
                                <Texts>
                                Разработка, внедрение и поддержка TMS систем.
                                </Texts>
                            </li>
                            <li className='li'>
                                <Texts>
                                Настройка интеграций с внешними сервисами, с ГИС системами (ЕГАИС, Честный знак, Меркурий, ФГИС Зерно, ЭТрН).
                                </Texts>
                            </li>
                            <li className='li'>
                                <Texts>
                                Разработка под СУБД MS SQL, PostgreSQL.
                                </Texts>
                            </li>
                            <li className='li'>
                                <Texts>
                                Поддержка DevOps подходов CI (Continuous integration, Непрерывная интеграция) и CD (Continuous delivery, Непрерывная доставка).
                                </Texts>
                            </li>
                            <li className='li'>
                                <Texts>
                                Разработка десктопных и мобильных приложений.
                                </Texts>
                            </li>
                        </ol>
                        <h2 className='title_content'><Texts >Технологии</Texts></h2>
                        <blockquote className='blockquote'>
                        Используем современные фреймворки: Flutter, Spring boot, Reactjs, Helidon, Camel, Express, Koa, Akka. 
                        Языки программирования: Java, C#, Python, Dart, JavaScript, ABAP, Kotlin, Groovy . 
                        СУБД: MS SQL, PostgreSQL.
                        </blockquote>
                        <Texts>У нас работают высококлассные специалисты с большим опытом в анализе, проектировании, разработке и внедрении различных ИТ-систем.</Texts>
                        <Link to={AppRoutes.ABOUT_TECH}><Texts><AppLinks>Полный стек технологий</AppLinks></Texts></Link> 
                        <h2 className='title_content'><Texts >Подходы</Texts></h2>
                        <blockquote className='blockquote'>
                        Прозрачность, качество, высокий уровень компетенции и проектного менеджмента.
                        </blockquote>
                        <Link to={AppRoutes.ABOUT}><Texts><AppLinks>Подробнее о нас</AppLinks></Texts></Link> 
                        {/* <Texts className='footer_content'>→ Отправить завяку на <Link to={AppRoutes.FEEDBACK}><AppLinks>разработку сайта</AppLinks></Link></Texts> */}
                    </div>
                    <div>
                        <div className='aside_nav'>
                            <div className='aside_title'><Texts width={600}>Компания</Texts> </div>
                            <ul role='aside_list'>
                                <li className='aside_nav_item'>
                                <Link to={AppRoutes.ABOUT}><Texts><AppLinks>О компании</AppLinks></Texts> </Link>
                                </li>
                                <li className='aside_nav_item'>
                                  <Link to={AppRoutes.ABOUT_TECH}><Texts><AppLinks>Стек технологий</AppLinks></Texts></Link>  
                                </li>
                                <li className='aside_nav_item'>
                                   <Link to={AppRoutes.ABOUT_VACANCY}><Texts> <AppLinks>Вакансии</AppLinks></Texts></Link>     
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServicesPage;