import { AppRoutes } from 'entity/routerConfig/ui/RouterConfig';
import React from 'react';
import { Link } from 'react-router-dom';
import { AppLinks } from 'shared/ui/applinks/AppLinks';
import { Texts } from 'shared/ui/texts/Texts';
import './ContactsPage.css';

const ContactsPage = () => {
    return (
        <div className='services_page_wrapper'>
        
        <div className='services_page_header'>
            <h1 style={{paddingLeft: 40, marginTop: 40}}><Texts>Контакты</Texts></h1>
        </div>
            <div className='contacts_page'>
                <div className='contact_page_content'>
                    <h2 className='title_content'><Texts >Телефон</Texts></h2>
                    <Texts className='p'> Челябинск <AppLinks href="tel:+73512160520">+7 (351) 216-05-20</AppLinks> </Texts>
                    <h2 className='title_content'><Texts >Сотрудничество</Texts></h2>
                    <Texts className='p'><AppLinks href="mailto:info@itmaster174.ru">info@itmaster174.ru</AppLinks> </Texts>
                    <h2 className='title_content'><Texts >Почтовый адрес</Texts></h2>
                    <Texts className='p'> 454052 г. Челябинск  ш. Металлургов д.88, оф.3.7 </Texts>
                    <h2 className='title_content'><Texts >Банковские реквизиты</Texts></h2>
                    <Texts className='p'>ООО «ИТ Мастер» <br />
                    ИНН 7453326162  КПП 746001001   <br />
                    454052 г. Челябинск  ш. Металлургов д.88, оф.3.7 <br />
                    ОГРН 1197456004580 от 30.01.2019 г.<br />
                    ОКТМО  75701330, ОКОФС 16, ОКОПФ 12165, ОКПО 35716597 <br />
                    Челябинское отделение № 8597 ПАО Сбербанк России,<br />
                    К/С 30101810700000000602, БИК 047501602 Р/С 40702810172000031680,</Texts>
                </div>
                {/* <iframe className='map' src="https://yandex.ru/map-widget/v1/?um=constructor%3A23b5588075c1867ebb673fbdab42c5ee54655484e25f80c5437d1a9d3eb76d65&amp;source=constructor" width="1280" height="720" ></iframe> */}
                <iframe className='map' src="https://yandex.ru/map-widget/v1/?um=constructor%3A7aaf71aea6c231acd74bafde9462a708b2b40469580f45e7c9fbf9e3ee098800&amp;source=constructor"  ></iframe>
            </div>
    </div>
    );
};

export default ContactsPage;