// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input {
    transition: 0.3s all;
    outline: none;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  input:focus {
    transition: 0.3s all;
    border-color: #4985FF;
  }
  `, "",{"version":3,"sources":["webpack://./src/shared/ui/inputs/Inputs.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,aAAa;IACb,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,oBAAoB;IACpB,qBAAqB;EACvB","sourcesContent":["input {\n    transition: 0.3s all;\n    outline: none;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n  }\n  \n  input:focus {\n    transition: 0.3s all;\n    border-color: #4985FF;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
