import React from 'react';
import { Navbar } from 'widgets/navbar';
import './Mainpage.css';
import { Texts } from 'shared/ui/texts/Texts';
import { AppLinks } from 'shared/ui/applinks/AppLinks';
import { Footerbar } from 'widgets/footerbar';
import { Link } from 'react-router-dom';
import { AppRoutes } from 'entity/routerConfig/ui/RouterConfig';

const Mainpage = () => {
    return (
        <div className='mainpage_wrapper'>
            <div className='container'>
              <section className='promo'>
                <h2 className='promo_title'><Texts>Системный интегратор <br /> и разработчик программного обеспечения</Texts></h2>
              </section>
              <section className='service'>
                <div className='section_grid'>
                    <div className='service_col'>
                        <h3 className='service_title'><Texts> Системная интеграция  </Texts></h3>
                    </div>
                    <div className='service_col'>
                        <div className='service_description_items'>
                            <p className='description_item'><Texts width={400}>Предлагаем полный спектр услуг от анализа и проектирования ваших бизнес-процессов до внедрения и поддержки готовых решений.</Texts></p>
                            <p className='description_item'><Texts width={400}>Создаем интегрированные решения, объединяем различные технологии и системы в единую современную инфраструктуру для повышения эффективности вашего бизнеса.</Texts></p>
                            <p className='description_item'><Texts width={400}>Разрабатываем и настраиваем интеграцию с ГИС системами.</Texts></p>
                            <Link to={AppRoutes.ABOUT_TECH}><Texts><AppLinks>Подробнее о технологиях</AppLinks></Texts></Link> 
                        </div>
                    </div>
                </div>
              </section>
              <section className='service'>
                <div className='section_grid'>
                    <div className='service_col'>
                        <h3 className='service_title'><Texts > Разработка программного  <br /> обеспечения </Texts></h3>
                    </div>
                    <div className='service_col'>
                        <div className='service_description_items'>
                            <p className='description_item'><Texts width={400}>Разрабатываем клиент-серверные приложения на основе микросервисной архитектуры Service mesh .</Texts></p>
                            <p className='description_item'><Texts width={400}>Обеспечиваем экспертизу от тимлидов с опытом разработки более 10 лет.</Texts></p>
                            <Link to={AppRoutes.ABOUT_TECH}><Texts><AppLinks>Подробнее о технологиях</AppLinks></Texts></Link> 
                        </div>
                    </div>
                </div>
              </section>
              <section className='service'>
                <div className='section_grid'>
                    <div className='service_col'>
                        <h3 className='service_title'><Texts> ИТ Аудит </Texts></h3>
                    </div>
                    <div className='service_col'>
                        <div className='service_description_items'>
                            <p className='description_item'><Texts width={400}>Проводим комплексную оценку информационных технологий вашей компании, оптимизируем ИТ-процессы и технологии для повышения эффективности бизнеса. </Texts></p>
                            {/* <p className='description_item'><Texts width={400}>Проводим комплексную оценку информационных технологий вашей компании, оптимизируем ИТ-процессы и технологии для повышения эффективности бизнеса. </Texts></p> */}
                        </div>
                    </div>
                </div>
              </section>
              <section className='service'>
                <div className='section_grid'>
                    <div className='service_col'>
                        <div className='service_description_items'>
                            <h3 className='service_title'><Texts> Надежность  и <br /> опыт </Texts></h3>
                            <p className='description_item'><Texts width={400}>Более 5 лет успешно работаем в отраслях: </Texts></p>
                            <p className='description_item'><Texts width={700}>Ритейл <br /> Строительство <br /> Управление недвижимостью </Texts></p>
                            <Link to={AppRoutes.ABOUT}><Texts><AppLinks>Подробнее о нашей компании</AppLinks></Texts></Link>
                            
                        </div>
                    </div>
                    <div className='service_col'>
                        <div className='service_description_items'>
                        <h3 className='service_title'><Texts> Развитие и поддержка </Texts></h3>
                            <p className='description_item'><Texts width={400}>Принимаем действующие проекты на сопровождение и развитие.</Texts></p>
                            <p className='description_item'><Texts width={400}>Разрабатываем и внедряем различные виды систем (WMS, TMS, SAP, 1С и.т.п).</Texts></p>
                            {/* <p className='description_item'><Texts width={400}>Реализуем новую функциональность и исправляем ошибки действующей.</Texts></p> */}
                            {/* <Link to={AppRoutes.FEEDBACK}><Texts><AppLinks>Оставить заявку</AppLinks></Texts></Link>  */}
                        </div>
                    </div>
                </div>
              </section>
            </div>
        </div>
    );
};

export default Mainpage;