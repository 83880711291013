// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.services_page_wrapper {
    margin-top: -10px;
    
}

.contacts_page {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap; 
    max-width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    
}

.map {
    width: 100%;
    min-height: 620px;
    max-height: 100%;
    box-shadow: 1px 1px 5px 5px #3333336b;
    border: 2px solid rgba(128, 128, 128, 0.473);
    border-radius: 5px;
}

.contact_page_content {
    max-width: 100%;
    line-height: 30px;
    padding-right: 50px;
}


@media (max-width: 980px) {
    .contacts_page {
        max-width: 100%;
        flex-wrap: wrap;
    }
    .contact_page_content {
        line-height: 45px;
        width: 100%;
    }
    
  }
`, "",{"version":3,"sources":["webpack://./src/pages/contactsPage/ui/ContactsPage.css"],"names":[],"mappings":";AACA;IACI,iBAAiB;;AAErB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,iBAAiB;IACjB,eAAe;IACf,kBAAkB;IAClB,mBAAmB;;AAEvB;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,gBAAgB;IAChB,qCAAqC;IACrC,4CAA4C;IAC5C,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,mBAAmB;AACvB;;;AAGA;IACI;QACI,eAAe;QACf,eAAe;IACnB;IACA;QACI,iBAAiB;QACjB,WAAW;IACf;;EAEF","sourcesContent":["\n.services_page_wrapper {\n    margin-top: -10px;\n    \n}\n\n.contacts_page {\n    display: flex;\n    justify-content: space-between;\n    flex-wrap: nowrap; \n    max-width: 100%;\n    padding-left: 40px;\n    padding-right: 40px;\n    \n}\n\n.map {\n    width: 100%;\n    min-height: 620px;\n    max-height: 100%;\n    box-shadow: 1px 1px 5px 5px #3333336b;\n    border: 2px solid rgba(128, 128, 128, 0.473);\n    border-radius: 5px;\n}\n\n.contact_page_content {\n    max-width: 100%;\n    line-height: 30px;\n    padding-right: 50px;\n}\n\n\n@media (max-width: 980px) {\n    .contacts_page {\n        max-width: 100%;\n        flex-wrap: wrap;\n    }\n    .contact_page_content {\n        line-height: 45px;\n        width: 100%;\n    }\n    \n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
