// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link_blue {
    color: #4985FF;
    cursor: pointer;
    border-bottom: 1px solid #4986ff80;
    transition: 0.3s all;
}

.link_blue:hover {
    color: rgba(213, 0, 50, .3);
    border-bottom: solid 1px rgba(213, 0, 50, .3);
    transition: 0.3s all;
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/applinks/AppLinks.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;IACf,kCAAkC;IAClC,oBAAoB;AACxB;;AAEA;IACI,2BAA2B;IAC3B,6CAA6C;IAC7C,oBAAoB;AACxB","sourcesContent":[".link_blue {\n    color: #4985FF;\n    cursor: pointer;\n    border-bottom: 1px solid #4986ff80;\n    transition: 0.3s all;\n}\n\n.link_blue:hover {\n    color: rgba(213, 0, 50, .3);\n    border-bottom: solid 1px rgba(213, 0, 50, .3);\n    transition: 0.3s all;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
