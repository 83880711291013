import React from 'react';
import './FeedbackPage.css'
import { TextSize, Texts } from 'shared/ui/texts/Texts';
import { Inputs } from 'shared/ui/inputs/Inputs';
import Buttons, { SizeButtons } from 'shared/ui/buttons/Buttons';

const FeedbackPage = () => {
    return (
        <div className='feedback_page'>
            <div className='container'>
                <h1 className='feedback_title'> <Texts>Отправить заявку на разработку: создание и развитие сайта, фронтенд аутсорсинг</Texts></h1>
                <form className='feedback_form_content'>
                    <div className='feedback_item'>
                        <label htmlFor="name" className='feedback_page_label'>Представьтесь, пожалуйста</label>
                        <Inputs maxLength={280} className='feedback_page_input' name='name' data-name="name" placeholder='' id='name'/>
                    </div>
                    <div className='feedback_item'>
                        <label htmlFor="contacts" className='feedback_page_label'>Как с вами связаться?</label>
                        <Inputs maxLength={280} className='feedback_page_input' name='contacts' data-name="contacts" placeholder='Номер телефона, email, skype или другой способ' id='contacts'/>
                    </div>
                    <div className='feedback_item'>
                        <label htmlFor="brief" className='feedback_page_label'>Коротко опишите Ваш проект</label>
                        <textarea className='feedback_page_textarea' name='brief' data-name="brief" placeholder='' id='brief'/>
                    </div>
                    <div id='file' className='file_upload'>
                        <input className='file_upload_input' accept=".jpg, .jpeg, .png, .pdf, .txt, .doc, .docx, .xls, .xlsx, .odt, .rtf, .zip, .rar" 
                        type="file" name="file-2" id="file-2" aria-hidden="true" style={{width: "0px", height: "22px"}} tabIndex={-1} />
                        <label htmlFor='file-2' role='button' tabIndex={0} className='feedback_page_upload_text'><div className='file_upload_text'>Прикрепите файл </div> </label>
                        <Texts> (до 10 мб)</Texts>
                    </div>
                    <Buttons type='submit' size={SizeButtons.XL} className='feedback_form_submit_btn' data-wait=" Пожалуйста подождите"> <Texts align='center' size={TextSize.M} color='white'>Отправить</Texts> </Buttons>
                </form>
            </div>
        </div>
    );
};

export default FeedbackPage;