import React, {
    InputHTMLAttributes, useRef,
} from 'react';
import './Inputs.css';

type HTMLInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'>

interface MyInputProps extends HTMLInputProps {
   typeInput?: string,
   value?: string | number,
   autoFocus?: boolean,
   onChange?: (value: any) => void,
   disabled?: boolean,
}

export const Inputs = (props: MyInputProps) => {
    const {
        typeInput = 'text', value, className, onChange, autoFocus, disabled, ...otherProps
    } = props;

    const inputRef = useRef<HTMLInputElement>(null);

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange?.(e.target.value);
    };

    return (
        <input
            ref={inputRef}
            className={className}
            type={typeInput}
            value={value}
            onChange={(e) => onChangeHandler(e)}
            disabled={disabled}
            {...otherProps}
        />
    );
};