// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid #d0d5da;
    max-width: 100%;
}

.navbar_logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar_text_logo {
    
    
    display: block;
}

.navbar_text_logo::after {
    content: "ИТ МАСТЕР";
}

.nav {
    display: flex;
    flex-direction: row;
    grid-column-gap: 32px;
}

.nav_item {
    font-size: 18px; 
    font-weight: 500;
    color: #4985FF;
    border-bottom: 1px solid #4986ff80;
    transition: 0.3s all;
}

.logo {
    max-width: 50px;
}

@media (max-width: 980px) {
    .nav_item {
        /* font-size: 28px; */
        font-size: 3vw;
    }
    /* .navbar_text_logo {
        display: none;
   } */
   .navbar_text_logo::after {
    content: "ИТ";
   }
   .logo {
        display: flex;
        max-width: 80px;
   }
  }

.nav_item:hover {
    cursor: pointer;
    font-weight: 500;
    color: rgb(247, 78, 78);
    border-bottom: 1px solid rgb(247, 78, 78);
    transition: 0.3s all;
}`, "",{"version":3,"sources":["webpack://./src/widgets/navbar/ui/Navbar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;IACjB,oBAAoB;IACpB,gCAAgC;IAChC,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;;;IAGI,cAAc;AAClB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,qBAAqB;AACzB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,kCAAkC;IAClC,oBAAoB;AACxB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI;QACI,qBAAqB;QACrB,cAAc;IAClB;IACA;;MAEE;GACH;IACC,aAAa;GACd;GACA;QACK,aAAa;QACb,eAAe;GACpB;EACD;;AAEF;IACI,eAAe;IACf,gBAAgB;IAChB,uBAAuB;IACvB,yCAAyC;IACzC,oBAAoB;AACxB","sourcesContent":[".navbar {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding-left: 40px;\n    padding-right: 40px;\n    padding-top: 25px;\n    padding-bottom: 25px;\n    border-bottom: 1px solid #d0d5da;\n    max-width: 100%;\n}\n\n.navbar_logo {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.navbar_text_logo {\n    \n    \n    display: block;\n}\n\n.navbar_text_logo::after {\n    content: \"ИТ МАСТЕР\";\n}\n\n.nav {\n    display: flex;\n    flex-direction: row;\n    grid-column-gap: 32px;\n}\n\n.nav_item {\n    font-size: 18px; \n    font-weight: 500;\n    color: #4985FF;\n    border-bottom: 1px solid #4986ff80;\n    transition: 0.3s all;\n}\n\n.logo {\n    max-width: 50px;\n}\n\n@media (max-width: 980px) {\n    .nav_item {\n        /* font-size: 28px; */\n        font-size: 3vw;\n    }\n    /* .navbar_text_logo {\n        display: none;\n   } */\n   .navbar_text_logo::after {\n    content: \"ИТ\";\n   }\n   .logo {\n        display: flex;\n        max-width: 80px;\n   }\n  }\n\n.nav_item:hover {\n    cursor: pointer;\n    font-weight: 500;\n    color: rgb(247, 78, 78);\n    border-bottom: 1px solid rgb(247, 78, 78);\n    transition: 0.3s all;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
