import { AppRoutes } from 'entity/routerConfig/ui/RouterConfig';
import React from 'react';
import { Link } from 'react-router-dom';
import { AppLinks } from 'shared/ui/applinks/AppLinks';
import { Texts } from 'shared/ui/texts/Texts';

const ServicesPageFront = () => {
    return (
        <div>
            <div className='services_page_wrapper'>
            <div className='container'>
            <div className='services_page_header'>
                            <h1><Texts>ИТ-аутсорсинг</Texts></h1>
                            <nav className="inner_nav">
                                <ul className="inner_nav_list" role='list'>
                                    <li className='inner_nav_item'>
                                       <Link to={AppRoutes.SERVICES}> <AppLinks  aria-current="page">Разработка</AppLinks></Link>
                                    </li>
                                    <li className='inner_nav_item'>
                                       <Link to={AppRoutes.SERVICES_DEV}><AppLinks  aria-current="page">Поддержка и развитие</AppLinks></Link> 
                                    </li>
                                    <li className='inner_nav_item'>
                                       <Link to={AppRoutes.SERVICES_FRONT}><AppLinks className='active' aria-current="page">ИТ-аутсорсинг</AppLinks></Link> 
                                    </li> 
                                </ul>
                            </nav>
                        </div>
                <div className='services_page_grid'>
                    <div className='services_page_content'>
                    <h2 className='title_content'><Texts >Преимущества</Texts></h2>
                        <blockquote className='blockquote'>
                        Профессионально оказываем услуги комплексного ИТ-аусорсинга. Мы разрабатываем программное обеспечение – начиная от мобильных приложений заканчивая десктопными, и внедряем сложные ИТ-системы для вашего бизнеса.
                        </blockquote>
                        <h2 className='title_content'><Texts >Мы предлагаем</Texts></h2>
                        <ul role='list' className='ul'>
                            <li className='li'>
                                <Texts>
                                Развитие и поддержка ИТ-систем предприятия, коммуникация с подрядчиками и контроль выполнения работ.
                                </Texts>
                            </li>
                            <li className='li'>
                                <Texts>
                                Разработка, внедрение и поддержка собственных приложений и сложных ИТ-систем.
                                </Texts>
                            </li>
                            <li className='li'>
                                <Texts>
                                Настройка интеграций с внешними сервисами и ГИС системами.
                                </Texts>
                            </li>
                        </ul>
                        <h2 className='title_content'><Texts >Технологии</Texts></h2>
                        <blockquote className='blockquote'>
                                <Texts>
                                – HTML, CSS, Java, C#, Dart, JavaScript, TypeScript ABAP, Kotlin.
                                </Texts>
                                <Texts>
                                – Flutter, Reactjs, Spring boot, Helidon, Camel, Express, Koa, Akka
                                </Texts>
                                <Texts>
                                – Apache Kafka, RabbitMQ, MQTT
                                </Texts>
                                <Texts>
                                – SAP PI/PO, MuleEsb, Node-Red
                                </Texts>
                        </blockquote>
                        <Texts className='p'>Полный <Link to={AppRoutes.SERVICES_FRONT_TECH}><AppLinks>стек технологий</AppLinks></Link></Texts>
                        {/* <h2 className='title_content'><Texts >HTML/CSS верстка макетов дизайна</Texts></h2>
                        <ul role='list' className='ul'>
                            <li className='li'>
                                <Texts>
                                Верстаем макеты с использованием технологий HTML, CSS, JavaScript.
                                </Texts>
                            </li>
                            <li className='li'>
                                <Texts>
                                Реализуем адаптивные и отзывчивые интерфейсы для качественного отображения сайта на различных устройствах и размерах экрана.
                                </Texts>
                            </li>
                            <li className='li'>
                                <Texts>
                                При необходимости поддерживаем устаревшие браузеры.
                                </Texts>
                            </li>
                            <li className='li'>
                                <Texts>
                                Прорабатываем семантику верстки и микроразметку данных, чтобы улучшить качество поисковой выдачи.
                                </Texts>
                            </li>
                            <li className='li'>
                                <Texts>
                                Прорабатываем доступность для людей с ограниченными возможностями или разрабатываем специальную версию шаблонов в соответствии с рекомендациями WCAG.
                                </Texts>
                            </li>
                        </ul> */}
                        <Texts className='p'>Поддержка DevOps подходов CI (Continuous integration, Непрерывная интеграция) и CD (Continuous delivery, Непрерывная доставка).</Texts>
                        {/* <h2 className='title_content'><Texts >Поддержка и консалтинг</Texts></h2>
                        <ul role='list' className='ul'>
                            <li className='li'>
                                <Texts>
                                Проводим аудит качества интерфейса.
                                </Texts>
                            </li>
                            <li className='li'>
                                <Texts>
                                Предоставляем подробные отчеты и рекомендации по оптимизации.
                                </Texts>
                            </li>
                        </ul> */}
                    </div>
                    <div>
                        <div className='aside_nav'>
                            <div className='aside_title'><Texts width={600}>Компания</Texts> </div>
                            <ul role='aside_list'>
                                <li className='aside_nav_item'>
                                <Link to={AppRoutes.ABOUT}><Texts><AppLinks>О компании</AppLinks></Texts> </Link> 
                                </li>
                                <li className='aside_nav_item'>
                                    <Link to={AppRoutes.ABOUT_TECH}><Texts><AppLinks>Стек технологий</AppLinks></Texts></Link>
                                </li>
                                <li className='aside_nav_item'>
                                   <Link to={AppRoutes.ABOUT_VACANCY}><Texts><AppLinks>Вакансии</AppLinks></Texts> </Link>   
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
};

export default ServicesPageFront;